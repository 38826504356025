export const MAPBOX_READY = "MAPBOX_READY";
export const RESIZE_WINDOW = "RESIZE_WINDOW";
export const MAPBOX_VIEWPORT_CHANGE = "MAPBOX_VIEWPORT_CHANGE";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_STEP_MODE = "SET_STEP_MODE";
export const SET_STEP_PARAMS = "SET_STEP_PARAMS";
export const SET_STEP_DATA = "SET_STEP_DATA";
export const SET_PVWATTS_DATA = "SET_PVWATTS_DATA";
export const SET_LEAD_DATA = "SET_LEAD_DATA";
export const SET_MAPBOX_REF = "SET_MAPBOX_REF";
export const GEOCODE_RESULT = "GEOCODE_RESULT";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const ERROR_CONFIRM = "ERROR_CONFIRM";
export const SET_BOUNDARY_GEOJSON = "SET_BOUNDARY_GEOJSON";
export const SET_START_REGION = "SET_START_REGION";
export const SET_CONSTANT_DATA = "SET_CONSTANT_DATA";
export const SET_HELP_MESSAGE = "SET_HELP_MESSAGE";