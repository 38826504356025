import React from "react";
import fetch from 'cross-fetch';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorMessage: null,
    errorInfo: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorMessage: error, errorInfo: errorInfo });
    //setTimeout(() => window.location.reload(), 500);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="errorBlock">
          <h4>An error has occurred!<br />The page is reloading...</h4>
        </div>
      );
    }
    return this.props.children;
  }
};

export default ErrorBoundary;