import wNumb from "wnumb";
//import { EditingMode, DrawPolygonMode, RotateMode } from 'react-map-gl-draw';
import { DrawPolygonMode, ModifyMode } from 'nebula.gl';

export const NREL_API_KEY = "8t2Z4fTtGsBdgULgJZarosSPH6RqlxZcitx6Nize";

export const GOOGLE_API_KEY = "AIzaSyA2_6k_G6U-O3fPYlOw7B8VNBDaqQeI1OU";

export const START_POINTS = {
	"CA": {
		id: "CA",
		name: "California",
		value: {
			latitude: 33.807,
			longitude: -118.210,
		}
	},
	"FL": {
		id: "FL",
		name: "Florida",
		value: {
			latitude: 25.877,
			longitude: -80.324,
		}
	}
};

export const MAPBOX = {
	API_TOKEN: 'pk.eyJ1IjoiZ3JlZW1wb3dlciIsImEiOiJja28xczV2bm0wbXBtMm5sY2tjZGQxMXg4In0.Lk6a2RkDD5Ij_R8X55sZJQ',
	VIEWPORT: {
		width: '100%',
		height: '100vh',
		zoom: 7.5,
		mapStyle: 'mapbox://styles/mapbox/satellite-v9',
		...START_POINTS.CA.value,
	}
}

export const GOOGLE = {
	API_KEY: 'AIzaSyAMBGodouokahvZs1kJpELFwZ_rHMcG108',
};

export const MODES = {
	PIN: {
		CONFIRM: { id: 'CONFIRM' },
		CHANGE: { id: 'CHANGE' },
	},
	DRAW_TOOL: {
		DRAW: { id: 'DRAW' },
		EDIT: { id: 'EDIT' },
	},
	ACTION: {
		DELETE: { id: 'DELETE' },
		ROTATE: { id: 'ROTATE' },
	}
}
