import React from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import MapBox from "../../components/MapBox/MapBox";
import ModalComponent from "../../components/Modal/Modal";
import CircleChart from "../../components/CircleChart/CircleChart";
import { Button, Modal, Form, InputGroup, FormControl, Row, Col, OverlayTrigger, Popover, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import { MAPBOX, MODES, START_POINTS } from "../../constants";
import { changeWindowSize, setActiveStep, setStepMode, setStepData, getUtilityRate, setStepParams, addLeadData, pushLeadCRM, geocodeAddress, errorConfirm, setStartRegion, getConstantData, setHelpMessage } from '../../redux/actions';
import "../../styles.css";
import _ from "lodash";
import 'bootstrap/dist/css/bootstrap.min.css';
import PersonalInfoForm from '../../components/Form/Form';
import roofShinglesImg from '../../images/Shingles.png';
import roofClayImg from '../../images/ClayTerracota.png';
import roofConcreteImg from '../../images/ConcreteTiles.png';
import roofOtherImg from '../../images/Other.png';
import roofPitchedImg from '../../images/Pitched.png';
import roofFlatImg from '../../images/Flat.png';
import cashImg from '../../images/Cash.png';
import financeImg from '../../images/Finance.png';
import batteryLGImg from '../../images/LG RESU10H.png';
import batteryEnphaseImg from '../../images/Enphase Encharge 10.png';
import batteryTeslaImg from '../../images/Tesla - Powerwall 2.png';
import batteryNoneImg from '../../images/battery_none.png';


function UtilityRate({ utilityData }) {
  if(!utilityData.kWh_year){
    return (
      <span><Spinner animation="border" size="sm" /></span>
    );
  }

  return (
    <span className="kwh-value">{utilityData.kWh_year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh</span>
  );
}

class SetYourPanelsPage extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;

      this.changeWindowSizeState = props.changeWindowSize;
    }

    componentWillMount(){
      const changeWindowSizeState = () => {
        const width = $(window).width();
        const height = $(window).height();
        this.changeWindowSizeState(width, height);
      }

      changeWindowSizeState();

      const _this = this;
      $(window).on('resize', function(){
        changeWindowSizeState();
      });
    }

    componentDidMount(){
      const urlParams = new URLSearchParams(window.location.search);
      const addressQuery = urlParams.get('address');
      if(addressQuery){
        this.props.geocodeAddress({ address: addressQuery });
      } else {
        this.props.setStepMode({ mode: MODES.PIN.CHANGE.id, stepIndex: 0 });
      }
      this.props.getConstantData();
    }

    arraySum(array){
      let sum = 0;
        _.map(array, (val) => { sum = sum + val; });
      return sum;
    }

    render() {
      let currentStep = {};
      const stepsList = this.props.stepsList;
      if(this.props.currentStep !== null){
        currentStep = {
          ...stepsList[this.props.currentStep],
          index: this.props.currentStep,
        };
      }

        return (
          <>
            <MapBox mapboxApiAccessToken={ MAPBOX.API_TOKEN } />
            {this.props.currentStep !== 0 &&
              <div className={'back-button-wrapper'}>
                <Button size="sm" onClick={() => {
                  this.props.setActiveStep(this.props.currentStep - 1, { isFinished: false });
                  if(this.props.currentStep - 1 === 5){
                    this.props.setStepMode({ mode: MODES.DRAW_TOOL.EDIT.id, stepIndex: 5 });
                  }
                }}>Back</Button>
              </div>
            }
            {this.props.helpMessage &&
              <ModalComponent isOpen={true} titleText={this.props.helpMessage.title}>
                <div className="modal-conent error-message">
                  <p>{this.props.helpMessage.message}</p>
                  <Button onClick={() => this.props.setHelpMessage(null)}>{this.props.helpMessage.buttonText ? this.props.helpMessage.buttonText : "NEXT"}</Button>
                </div>
              </ModalComponent>
            }
            {(this.props.errorMessage !== null) &&
              <ModalComponent isOpen={true} titleText={"Error: " + this.props.errorMessage.error}>
                <div className="modal-conent error-message">
                  <p>{this.props.errorMessage.message}</p>
                  <Button onClick={this.props.errorConfirm}>NEXT</Button>
                </div>
              </ModalComponent>
            }
            {stepsList[5].isActive && stepsList[5].data.pvwatts && Object.keys(stepsList[5].data.pvwatts).length > 0 &&
              <CircleChart produced={this.arraySum(_.map(stepsList[5].data.pvwatts, (pvwatts) => { return pvwatts.panels_kWh; }))} needed={stepsList[1].data.kWh_year} isLoading={stepsList[5].data.isPanelsDataLoading} />
            }
            {stepsList[5].isActive && stepsList[5].params.directionIsSelected === true &&
              <div className={'sp-options-wrapper'}>
                <Form>
                  <OverlayTrigger
                    show={stepsList[5].data.panelsData === null}
                    placement={'right'}
                    overlay={
                      <Popover>
                        <Popover.Content>
                          Select your brand of panels!
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Form.Group controlId="redactorForm.PanelsType">
                      <DropdownButton className="dropdown-basic-button" title={stepsList[5].data.panelsData ? stepsList[5].data.panelsData.name : "Panels type"}>
                        {_.map(this.props.constants.PANELS, (panel, key) => {
                          return (
                            <Dropdown.Item key={key} active={_.isEqual(stepsList[5].data.panelsData, panel)} onClick={(e) => {
                              this.props.setStepData({ stepIndex: 5, data: { panelsData: panel } });
                              this.props.addLeadData({ 'PanelsType': panel.name });
                              //this.props.setStepParams({ params: { selectedFeatureIndex: null }, stepIndex: 5 });
                              this.props.setStepMode({ mode: "EDIT_PANELS", stepIndex: 5 });
                              this.props.setHelpMessage({ title: "remove panels", message: "You can remove unnecessary panels by click." });
                            }} >{panel.name}</Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </Form.Group>
                  </OverlayTrigger>
                  {stepsList[5].data.panelsData !== null &&
                    <>
                      <Form.Group controlId="redactorForm.Position">
                        <DropdownButton className="dropdown-basic-button" title={stepsList[5].data.panelsPosition ? stepsList[5].data.panelsPosition : "Select position"}>
                          <Dropdown.Item key={'Horizontal'} active={_.isEqual(stepsList[5].data.panelsPosition, 'Horizontal')} onClick={(e) => { this.props.setStepData({ stepIndex: 5, data: { panelsPosition: 'Horizontal' } }); this.props.addLeadData({ 'PanelsPosition': 'Horizontal' }); }} >Horizontal</Dropdown.Item>
                          <Dropdown.Item key={'Vertical'} active={_.isEqual(stepsList[5].data.panelsPosition, 'Vertical')} onClick={(e) => { this.props.setStepData({ stepIndex: 5, data: { panelsPosition: 'Vertical' } }); this.props.addLeadData({ 'PanelsPosition': 'Vertical' }); }} >Vertical</Dropdown.Item>
                      </DropdownButton>
                      </Form.Group>
                      <OverlayTrigger
                        show={stepsList[5].data.inverterType === null}
                        placement={'right'}
                        overlay={
                          <Popover>
                            <Popover.Content>
                              Select Inverter type!
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Form.Group controlId="redactorForm.InverterType">
                          <DropdownButton className="dropdown-basic-button" title={stepsList[5].data.inverterType ? stepsList[5].data.inverterType.name : "Inverter type"}>
                            {_.map(this.props.constants.INVERTERS, (inveter, key) => {
                              return (
                                <Dropdown.Item key={key} active={_.isEqual(stepsList[5].data.inverterType, inveter)} onClick={(e) => { this.props.setStepData({ stepIndex: 5, data: { inverterType: inveter } }); this.props.addLeadData({ 'InverterType': inveter.name }); }} >{inveter.name}</Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </Form.Group>
                      </OverlayTrigger>
                    </>
                  }
                </Form>
              </div>
            }
            {stepsList[5].isActive &&
              <div className={'tutorial-button-wrapper'}>
                <Button size="sm" target="_blank" href="https://www.youtube.com/embed/bCjeKfdhv44">Video tutorial</Button>
              </div>
            }
            {stepsList[0].isActive && stepsList[0].params.mode === MODES.PIN.CHANGE.id &&
              <>
                <div className={'map-heading-wrapper'}>
                  <p>
                    Set pin to your property
                  </p>
                </div>
                <div className={'map-button-wrapper'}>
                  <Button
                    onClick={() => {
                      this.props.setStartRegion( this.props.startRegion === START_POINTS['FL'].id ? START_POINTS['CA'].id : START_POINTS['FL'].id );
                    }}
                  >Go to { this.props.startRegion === START_POINTS['FL'].id ? START_POINTS['CA'].name : START_POINTS['FL'].name }</Button>
                </div>
              </>
            }
            {(stepsList[1].isActive) &&
              <ModalComponent isOpen={true} stepIndex={1} titleText={stepsList[1].title}>
                <div className="modal-conent">
                  <Form>
                    <Form.Label className="form-caption">Your average bill for electricity:</Form.Label>
                    <Form.Group controlId="formBasicRange">
                      <InputGroup className="mb-2 mr-sm-2">
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="number"
                          value={stepsList[1].data.averageBill}
                          required={true}
                          placeholder=""
                          onChange={(e) => {
                            this.props.setStepData({ stepIndex: 1, data: { averageBill: +e.target.value === 0 ? null : +e.target.value } });
                          }}
                        />
                      </InputGroup>
                      {stepsList[1].data.averageBill !== null && stepsList[1].data.averageBill < 50 &&
                        <p className="form-caption" style={{ color: "red" }}>The average bill must be at least $50</p>
                      }
                    </Form.Group>
                    <div className="modal-buttons-wrap">
                      <Button disabled={!stepsList[1].data.averageBill || (stepsList[1].data.averageBill < 50)} variant="primary" type="submit" onClick={() => {
                        this.props.getUtilityRate({ coords: { lon: stepsList[0].data.longitude, lat: stepsList[0].data.latitude }, averageBill: stepsList[1].data.averageBill });
                        this.props.addLeadData({ 'AverageBill': stepsList[1].data.averageBill });
                        this.props.setActiveStep(2);
                      }}>
                        NEXT
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalComponent>
            }
            {(stepsList[2].isActive) &&
              <ModalComponent size="lg" isOpen={true} stepIndex={2} titleText={stepsList[2].title}>
              <div className="modal-conent">
                <Form>
                  <Form.Group>
                    <div>
                      <Row>
                        <Col>
                          <div className="radio-button-wrap">
                            <input
                              name="roofMaterial"
                              type="radio"
                              value="Shingles"
                              id="roofMaterial_shingles"
                              className="form-check-input"
                              checked={stepsList[2].data.roofMaterial === "Shingles"}
                              onChange={(e) => {
                                if(e.target.value){
                                  this.props.addLeadData({ 'RoofMaterial': e.target.value });
                                  this.props.setStepData({ stepIndex: 2, data: { roofMaterial: e.target.value } });
                                }
                              }}
                            />
                            <div className="radio-block">
                              <div className="radio-block-inner">
                                <img src={roofShinglesImg} />
                                <label htmlFor="roofMaterial_shingles">.</label>
                              </div>
                              <label htmlFor="roofMaterial_shingles">Shingles</label>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="radio-button-wrap">
                            <input
                              name="roofMaterial"
                              type="radio"
                              value="Clay Terracota"
                              id="roofMaterial_clay"
                              className="form-check-input"
                              checked={stepsList[2].data.roofMaterial === "Clay Terracota"}
                              onChange={(e) => {
                                if(e.target.value){
                                  this.props.addLeadData({ 'RoofMaterial': e.target.value });
                                  this.props.setStepData({ stepIndex: 2, data: { roofMaterial: e.target.value } });
                                }
                              }}
                            />
                            <div className="radio-block">
                              <div className="radio-block-inner">
                                <img src={roofClayImg} />
                                <label htmlFor="roofMaterial_clay">.</label>
                              </div>
                              <label htmlFor="roofMaterial_clay">Clay terracota</label>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="radio-button-wrap">
                            <input
                              name="roofMaterial"
                              type="radio"
                              value="Concrete Tiles"
                              id="roofMaterial_concrete"
                              className="form-check-input"
                              checked={stepsList[2].data.roofMaterial === "Concrete Tiles"}
                              onChange={(e) => {
                                if(e.target.value){
                                  this.props.addLeadData({ 'RoofMaterial': e.target.value });
                                  this.props.setStepData({ stepIndex: 2, data: { roofMaterial: e.target.value } });
                                }
                              }}
                            />
                            <div className="radio-block">
                              <div className="radio-block-inner">
                                <img src={roofConcreteImg} />
                                <label htmlFor="roofMaterial_concrete">.</label>
                              </div>
                              <label htmlFor="roofMaterial_concrete">Concrete tiles</label>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="radio-button-wrap">
                            <input
                              name="roofMaterial"
                              type="radio"
                              value="Other"
                              id="roofMaterial_other"
                              className="form-check-input"
                              checked={stepsList[2].data.roofMaterial === "Other"}
                              onChange={(e) => {
                                if(e.target.value){
                                  this.props.addLeadData({ 'RoofMaterial': e.target.value });
                                  this.props.setStepData({ stepIndex: 2, data: { roofMaterial: e.target.value } });
                                }
                              }}
                            />
                            <div className="radio-block">
                              <div className="radio-block-inner">
                                <img src={roofOtherImg} />
                                <label htmlFor="roofMaterial_other">.</label>
                              </div>
                              <label htmlFor="roofMaterial_other">Other</label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form.Group>
                  <div className="modal-buttons-wrap">
                    <Button disabled={!stepsList[2].data.roofMaterial} variant="primary" type="submit" onClick={() => this.props.setActiveStep(3)}>
                      NEXT
                    </Button>
                  </div>
                </Form>
              </div>
              </ModalComponent>
            }
            {(stepsList[3].isActive) &&
              <ModalComponent isOpen={true} stepIndex={3} titleText={stepsList[3].title}>
                <div className="modal-conent">
                  <Form>
                    <Form.Group>
                      <div>
                        <Row>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="roofTilt"
                                type="radio"
                                value="20"
                                id="roofTilt_Pitched"
                                className="form-check-input"
                                checked={stepsList[3].data.roofTilt === "20"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'RoofTilt': "Pitched" });
                                    this.props.setStepData({ stepIndex: 3, data: { roofTilt: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={roofPitchedImg} />
                                  <label htmlFor="roofTilt_Pitched">.</label>
                                </div>
                                <label htmlFor="roofTilt_Pitched">Pitched</label>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="roofTilt"
                                type="radio"
                                value="10"
                                id="rroofTilt_Flat"
                                className="form-check-input"
                                checked={stepsList[3].data.roofTilt === "10"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'RoofTilt': "Flat" });
                                    this.props.setStepData({ stepIndex: 3, data: { roofTilt: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={roofFlatImg} />
                                  <label htmlFor="rroofTilt_Flat">.</label>
                                </div>
                                <label htmlFor="rroofTilt_Flat">Flat</label>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Form.Group>
                    <div className="modal-buttons-wrap">
                      <Button disabled={!stepsList[3].data.roofTilt} variant="primary" type="submit" onClick={() => this.props.setActiveStep(4)}>
                        NEXT
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalComponent>
            }
            {(stepsList[4].isActive) &&
              <ModalComponent isOpen={true} stepIndex={4} titleText={stepsList[4].title} size="lg">
                <div className="modal-conent modal-conent--center">
                  <h4>You need to produce <UtilityRate utilityData={stepsList[1].data} /> to cover your annual energy consumption</h4>
                  <div className="video-block">
                    <h3>Watch video tutorial on how to place panels</h3>
                    <div>
                      <iframe width="80%" height="360px" src="https://www.youtube.com/embed/bCjeKfdhv44" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                    </div>
                  </div>
                  <div className="modal-buttons-wrap">
                    <Button onClick={() => {
                      this.props.setActiveStep(5);
                      this.props.addLeadData({ 'KWhNeeded': stepsList[1].data.kWh_year });
                      this.props.setStepMode({ mode: MODES.DRAW_TOOL.DRAW.id, stepIndex: 5 });
                    }}>NEXT</Button>
                  </div>
                </div>
              </ModalComponent>
            }
            {(stepsList[5].isActive) && stepsList[5].params.mode === "EDIT_PANELS" &&
              <>
                <div className={'map-heading-wrapper'}>
                  <p>
                    Move, edit and delete panels
                  </p>
                </div>
                {this.props.stepsList[5].params.panelsIsPlaced && !this.props.stepsList[5].params.addPanelsAction &&
                  <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { addPanelsAction: false }, stepIndex: 5 });
                      if(this.props.stepsList[5].params.showFinishMessage === undefined){
                        this.props.setStepParams({ params: { showFinishMessage: true }, stepIndex: 5 });
                      }
                      this.props.setStepMode({ mode: "FINISH_MODE", stepIndex: 5 });
                    }}>SAVE</Button>
                    <Button onClick={() => {
                        this.props.setStepParams({ params: { addPanelsAction: true }, stepIndex: 5 });
                      }}>ADD PANELS</Button>
                    {this.props.stepsList[5].params.selectedPanelsIndexes && this.props.stepsList[5].params.selectedPanelsIndexes.length > 0 &&
                      <Button onClick={() => {
                        this.props.setStepParams({ params: { deletePanelsAction: true }, stepIndex: 5 });
                      }}>DELETE PANELS</Button>
                    }
                  </div>
                }
                {this.props.stepsList[5].params.addPanelsAction &&
                  <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { addPanelsAction: false }, stepIndex: 5 });
                    }}>CANCEL</Button>
                  </div>
                }
              </>
            }
            {(stepsList[5].isActive) && stepsList[5].params.mode === "FINISH_MODE" &&
              <>
                <div className={'map-heading-wrapper'}>
                  <p>
                    Delete unnecessary panels
                  </p>
                </div>
                {this.props.stepsList[5].params.panelsIsPlaced &&
                  <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: null, stepIndex: 5 });
                      this.props.setActiveStep(6);
                    }}>FINISH</Button>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: MODES.DRAW_TOOL.DRAW.id, stepIndex: 5 });
                    }}>ADD AREA</Button>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: MODES.DRAW_TOOL.EDIT.id, stepIndex: 5 });
                    }}>EDIT</Button>
                  </div>
                }
              </>
            }
            {(stepsList[5].isActive) && stepsList[5].params.mode === MODES.DRAW_TOOL.DRAW.id &&
              <>
                <div className={'map-heading-wrapper'}>
                  <p>
                    Draw your roof on map
                  </p>
                </div>
                {this.props.stepsList[5].params.panelsIsPlaced &&
                  <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: null, stepIndex: 5 });
                      this.props.setActiveStep(6);
                    }}>FINISH</Button>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: MODES.DRAW_TOOL.EDIT.id, stepIndex: 5 });
                    }}>EDIT</Button>
                  </div>
                }
              </>
            }
            {(stepsList[5].isActive) && stepsList[5].params.mode === MODES.DRAW_TOOL.EDIT.id && stepsList[5].params.selectedFeatureIndex !== null &&
              <>
                <div className={'map-heading-wrapper'}>
                  <p>
                    Edit polygon
                  </p>
                </div>
                {stepsList[5].params.directionIsSelected === true && stepsList[5].data.panelsData !== null &&
                  <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepMode({ mode: "EDIT_PANELS", stepIndex: 5 });
                    }}>NEXT</Button>
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { action: MODES.ACTION.DELETE.id }, stepIndex: 5 });
                    }}>DELETE</Button>
                  </div>
                }
              </>
            }
            {(stepsList[5].isActive) && stepsList[5].params.mode === MODES.DRAW_TOOL.EDIT.id && stepsList[5].params.selectedFeatureIndex === null &&
              <div className={'map-heading-wrapper'}>
                <p>
                  Select polygon for editing
                </p>
                <div className={'map-button-wrapper'}>
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { selectedFeatureIndex: null }, stepIndex: 5 });
                      this.props.setStepMode({ mode: MODES.DRAW_TOOL.DRAW.id, stepIndex: 5 });
                    }}>CANCEL</Button>
                  </div>
              </div>
            }
            {(stepsList[5].isActive) && stepsList[5].params.showFinishMessage === true &&
              <ModalComponent isOpen={true} stepIndex={6} titleText={""}>
                <div className="modal-conent modal-conent--center">
                  <h4>Are you finished or do you want to add more panels?</h4>
                  <div className="buttons-block">
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { showFinishMessage: false }, stepIndex: 5 });
                      this.props.setActiveStep(6);
                    }}>Finish</Button>
                    <Button onClick={() => {
                      this.props.setStepParams({ params: { showFinishMessage: false }, stepIndex: 5 });
                      this.props.setStepMode({ mode: "DRAW", stepIndex: 5 });
                    }}>Add Panels</Button>
                  </div>
                </div>
              </ModalComponent>
            }
            {(stepsList[6].isActive) &&
              <ModalComponent isOpen={true} stepIndex={6} titleText={stepsList[6].title}>
                <div className="modal-conent modal-conent--center">
                  <h4>Your actual usage: <span className="kwh-value">{stepsList[1].data.kWh_year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh</span></h4>
                  <h4>This design produces: <span className="kwh-value">{Math.round(this.arraySum(_.map(stepsList[5].data.pvwatts, (pvwatts) => { return pvwatts.panels_kWh; }))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh</span></h4>
                  <div className="buttons-block">
                    <Button onClick={() => {
                      this.props.addLeadData({ 'KWhProduced': Math.round(this.arraySum(_.map(stepsList[5].data.pvwatts, (pvwatts) => { return pvwatts.panels_kWh; }))) });
                      this.props.setActiveStep(7);
                    }}>Continue</Button>
                    <Button onClick={() => {
                      this.props.setActiveStep(5, { isFinished: false });
                      this.props.setStepMode({ mode: "FINISH_MODE", stepIndex: 5 });
                    }}>Back</Button>
                  </div>
                </div>
              </ModalComponent>
            }
            {(stepsList[7].isActive) &&
              <ModalComponent isOpen={true} stepIndex={7} titleText={stepsList[7].title}>
                <div className="modal-conent">
                  <PersonalInfoForm stepIndex={7} />
                </div>
              </ModalComponent>
            }
            {(stepsList[8].isActive) &&
              <ModalComponent size="lg" isOpen={true} stepIndex={8} titleText={stepsList[8].title}>
                <div className="modal-conent">
                  <Form>
                    <Form.Group>
                      <div>
                        <Row>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="batteryType"
                                type="radio"
                                value="LG RESU10H"
                                id="batteryType_LG"
                                className="form-check-input"
                                checked={stepsList[8].data.batteryType === "LG RESU10H"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'BatteryType': e.target.value });
                                    this.props.setStepData({ stepIndex: 8, data: { batteryType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={batteryLGImg} />
                                  <label htmlFor="batteryType_LG">.</label>
                                </div>
                                <label htmlFor="batteryType_LG">LG<br />RESU10H</label>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="batteryType"
                                type="radio"
                                value="Enphase Encharge 10"
                                id="batteryType_EE"
                                className="form-check-input"
                                checked={stepsList[8].data.batteryType === "Enphase Encharge 10"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'BatteryType': e.target.value });
                                    this.props.setStepData({ stepIndex: 8, data: { batteryType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={batteryEnphaseImg} />
                                  <label htmlFor="batteryType_EE">.</label>
                                </div>
                                <label htmlFor="batteryType_EE">Enphase<br />Encharge 10</label>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="batteryType"
                                type="radio"
                                value="Tesla - Powerwall 2"
                                id="batteryType_TESLA"
                                className="form-check-input"
                                checked={stepsList[8].data.batteryType === "Tesla - Powerwall 2"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'BatteryType': e.target.value });
                                    this.props.setStepData({ stepIndex: 8, data: { batteryType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={batteryTeslaImg} />
                                  <label htmlFor="batteryType_TESLA">.</label>
                                </div>
                                <label htmlFor="batteryType_TESLA">Tesla<br />Powerwall 2</label>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="batteryType"
                                type="radio"
                                value="don't need"
                                id="batteryType_none"
                                className="form-check-input"
                                checked={stepsList[8].data.batteryType === "don't need"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'BatteryType': e.target.value });
                                    this.props.setStepData({ stepIndex: 8, data: { batteryType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={batteryNoneImg} />
                                  <label htmlFor="batteryType_none">.</label>
                                </div>
                                <label htmlFor="batteryType_none">Don't need battery</label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form.Group>
                    <div className="modal-buttons-wrap">
                      <Button variant="primary" onClick={() => {
                          this.props.pushLeadCRM(this.props.leadData, stepsList[5].data.panelsData);
                      }}>
                        Get results
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalComponent>
            }
            {/*(stepsList[9].isActive) &&
              <ModalComponent isOpen={true} stepIndex={9} titleText={stepsList[9].title}>
                <div className="modal-conent">
                  <Form>
                    <Form.Group>
                      <div>
                        <Row>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="paymentType"
                                type="radio"
                                value="cash"
                                id="paymentType_cash"
                                className="form-check-input"
                                checked={stepsList[9].data.paymentType === "cash"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'PaymentType': e.target.value });
                                    this.props.setStepData({ stepIndex: 9, data: { paymentType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={cashImg} />
                                  <label htmlFor="paymentType_cash">.</label>
                                </div>
                                <label htmlFor="paymentType_cash">Cash</label>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="radio-button-wrap">
                              <input
                                name="paymentType"
                                type="radio"
                                value="finance"
                                id="paymentType_finance"
                                className="form-check-input"
                                checked={stepsList[9].data.paymentType === "finance"}
                                onChange={(e) => {
                                  if(e.target.value){
                                    this.props.addLeadData({ 'PaymentType': e.target.value });
                                    this.props.setStepData({ stepIndex: 9, data: { paymentType: e.target.value } });
                                  }
                                }}
                              />
                              <div className="radio-block">
                                <div className="radio-block-inner">
                                  <img src={financeImg} />
                                  <label htmlFor="paymentType_finance">.</label>
                                </div>
                                <label htmlFor="paymentType_finance">Finance</label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form.Group>
                    <div className='modal-buttons-wrap'>
                      <Button variant="primary" onClick={() => {
                          this.props.pushLeadCRM(this.props.leadData);
                      }}>
                        Get results
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalComponent>
              */
            }
          </>
        );
    };
}

const mapStateToProps = state => {
  return {
    leadData: state.steps.leadData,
    stepsList: state.steps.list,
    currentStep: state.steps.currentStep,
    errorMessage: state.steps.errorMessage,
    helpMessage: state.steps.helpMessage,
    startRegion: state.mapbox.startRegion,
    constants: state.window.constants,
  };
};

export default connect(
  mapStateToProps,
  { changeWindowSize, setActiveStep, setStepMode, setStepData, getUtilityRate, setStepParams, addLeadData, pushLeadCRM, geocodeAddress, errorConfirm, setStartRegion, getConstantData, setHelpMessage },
)(SetYourPanelsPage);



