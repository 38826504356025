import React, { useRef, useEffect, useState } from "react";
import $ from 'jquery';
import * as turf from '@turf/turf';
import { connect } from "react-redux";
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl';
import {  } from 'react-bootstrap';
import { MAPBOX, MODES, START_POINTS } from "../../constants";
import { mapboxViewportChange, changeWindowSize } from '../../redux/actions';
import DeckGL, { MapController } from 'deck.gl';
import { PolygonLayer } from '@deck.gl/layers';
import "../../styles.css";
import _ from "lodash";
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';
import { PlaneGeometry } from '@luma.gl/engine';
import 'bootstrap/dist/css/bootstrap.min.css';
import panelPatternImg from './panel.jpg';

function ShowPanelsPage(params) {
  const {
    mapboxViewportChange,
    viewport,
    changeWindowSize
  } = params;

  const panelsGeometry = JSON.parse(localStorage.getItem('panelsGeometry'));
  const panelsData = JSON.parse(localStorage.getItem('panelsData'));

  useEffect(() => {
    const changeWindowSizeState = () => {
      const width = $(window).width();
      const height = $(window).height();
      changeWindowSize(width, height);
    }

    changeWindowSizeState();

    $(window).on('resize', function(){
      changeWindowSizeState();
    });
  },[]);

  let _map = useRef(null);
  let _deck = useRef(null);
  const [mapboxReady, setMapboxReady] = useState(false);

  useEffect(() => {
    if(mapboxReady){
      const bboxArray = turf.bbox(panelsGeometry);

      const bbox = [ [bboxArray[0],bboxArray[1]], [bboxArray[2], bboxArray[3]] ];

      const flyToProps = _map.current.getMap().cameraForBounds(bbox, {
        padding: {top: 10, bottom: 10, left: 10, right: 10}
      });

      updateViewport({
        latitude: flyToProps.center.lat,
        longitude: flyToProps.center.lng,
        zoom: flyToProps.zoom - 1,
      });
    }
  }, [mapboxReady]);

  if(!(panelsGeometry && panelsData)){
    return null;
  }

  const updateViewport = viewport => {
    mapboxViewportChange(viewport);
  };

  const panelsLayer = new PolygonLayer({
    id: 'panels-layer',
    data: panelsGeometry.features[0].geometry.coordinates,
    _normalize: true,
    pickable: true,
    stroked: true,
    filled: true,
    getPolygon: d => {
      return d;
    },
    getFillColor: d => [0, 180, 255, 0],
    getLineColor: (d) => {
      return [0, 180, 255, 255];
    },
    getLineWidth: 4,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 4,
    lineWidthScale: 0,
    lineWidthUnits: 'pixels',
  });

  const meshLayer = new SimpleMeshLayer({
    id: 'mesh-layer',
    data: panelsGeometry.features[0].geometry.coordinates,
    pickable: false,
    getOrientation: d => {
      const coords = d[0];
      const line1 = turf.lineString([coords[0],coords[1]]);
      const line2 = turf.lineString([coords[1],coords[2]]);
      const longestSide = turf.length(line1) > turf.length(line2) ? line2 : line1;

      const centerOfSide = turf.centerOfMass(longestSide);

      const bearing = turf.bearing(turf.centerOfMass(turf.polygon(d)), centerOfSide);

      return [0, -(bearing + 180), 0];
    },
    getPosition: d => {
      return turf.center(turf.polygon(d)).geometry.coordinates;
    },
    getColor: [255, 255, 255],
    getScale: d => {
      return [1, 1, 1];
    },
    texture: panelPatternImg,
    mesh: panelsData && new PlaneGeometry({
      type: 'x,y',
      xlen: ((panelsData.height - 10) / 1000),
      ylen: ((panelsData.width - 110) / 1000),
      nx: 5,
      nz: 5,
      offset: 0
    })
  });

  return (
      <ReactMapGL
        ref={_map}
        mapboxApiAccessToken = { MAPBOX.API_TOKEN }
        {...viewport}
        onLoad={() => setMapboxReady(true)}
        onViewportChange={(viewport) => updateViewport(viewport)}
        dragPan={false}
        dragRotate={false}
        scrollZoom={false}
      >
        <DeckGL
          ref={ref => _deck = ref}
          initialViewState = {{
            ...viewport,
          }}
          controller={{type: MapController, dragRotate: false, dragPan: false, scrollZoom: false }}
          layers={[
            meshLayer,
            panelsLayer,
          ]}
        />
      </ReactMapGL>
  );
};

const mapStateToProps = state => {
  return {
    viewport: state.mapbox.viewport,
  };
};

export default connect(
  mapStateToProps,
  { mapboxViewportChange, changeWindowSize },
)(ShowPanelsPage);
