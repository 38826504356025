import { combineReducers } from "redux";
import mapbox from "./mapbox";
import window from "./window";
import steps from "./steps";


export default combineReducers({
	mapbox,
	window,
	steps,
});
