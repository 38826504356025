import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ErrorBoundary from './ErrorBoundary';
import SetYourPanelsPage from './pages/SetYourPanelsPage/SetYourPanelsPage';
import ShowPanelsPage from './pages/ShowPanelsPage/ShowPanelsPage';
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";


class App extends React.Component {
  	constructor(props) {
  		super(props);
  		this.props = props;
  	}

  	render() {
  		return (
      <ErrorBoundary>
  			<div className="solar-panels-app">
          <Switch>
            <Route exact path='/' component={SetYourPanelsPage} />
            <Route exact path='/show-panels' component={ShowPanelsPage} />
            <Route path='*' component={SetYourPanelsPage}>
              <Redirect to="/" />
            </Route>
          </Switch>
  			</div>
      </ErrorBoundary>
		);
  	}
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(
  mapStateToProps,
  {},
)(App);