import { MAPBOX_READY, RESIZE_WINDOW, MAPBOX_VIEWPORT_CHANGE, SET_MAPBOX_REF, GEOCODE_RESULT, SET_BOUNDARY_GEOJSON, SET_START_REGION } from "../actionTypes";
import { MAPBOX, START_POINTS } from "../../constants";

const initialState = {
  mapboxRef: null,
  startRegion: START_POINTS.CA.id,
  isMapboxReady: false,
  geocodeResult: null,
  boundaryGeojson: null,
  viewport: {
    ...MAPBOX.VIEWPORT,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_BOUNDARY_GEOJSON: {
      return {
        ...state,
        boundaryGeojson: action.payload.geojson,
      };
    }
    case SET_START_REGION: {
      return {
        ...state,
        startRegion: action.payload.id,
      };
    }
    case MAPBOX_READY: {
      return {
        ...state,
        isMapboxReady: true,
      };
    }
    case RESIZE_WINDOW: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          width: action.payload.width,
          height: action.payload.height,
        }
      };
    }
    case MAPBOX_VIEWPORT_CHANGE: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          ...action.payload.viewport,
        }
      };
    }
    case SET_MAPBOX_REF: {
      return {
        ...state,
        mapboxRef: action.payload.ref,
      };
    }
    case GEOCODE_RESULT: {
      return {
        ...state,
        geocodeResult: action.payload.result,
      };
    }
    default:
      return state;
  }
}
