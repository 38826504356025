import React from "react";
import { connect } from "react-redux";
import ProgressBar from 'progressbar.js';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';
//import {  } from '../../redux/actions';


class CircleChart extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.barObj = null;
    this.state = {
      progress: 0.5,
    };
  }

  componentDidMount() {
    this.barObj = new ProgressBar.Circle('#circleChart', {
      color: '#aaa',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 6,
      trailWidth: 6,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        autoStyleContainer: false
      },
      fill: 'rgba(255, 255, 255, 1)',
      from: { color: '#FF0000', width: 6 },
      to: { color: '#0ACF00', width: 6 },
      // Set default step function for all animate calls
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);

        var value = Math.round(circle.value() * 100);

        if(value >= 100 && circle.path.style.strokeDashoffset !== 0){
          //circle.path.style.setProperty('stroke-dashoffset', 0);
        }

        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value + "%");
        }
      }
    });
    this.barObj.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
    this.barObj.text.style.fontSize = '2rem';

    if(this.props.needed && this.props.produced){
      this.barObj.animate(this.props.produced/this.props.needed);
    }
  }

  componentDidUpdate() {
    if(this.props.needed && this.props.produced){
      this.barObj.animate(this.props.produced/this.props.needed);
    }
  }

  render() {
    return (
      <div id="circleChart" className="circle-chart"></div>
    );
  };

}

const mapStateToProps = state => {
  return {
    stepsList: state.steps.list,
  };
};

export default connect(mapStateToProps, {  })(CircleChart);