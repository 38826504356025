import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from 'react-bootstrap';
import { setActiveStep, setStepData, addLeadData } from '../../redux/actions';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import _ from "lodash";
import $ from "jquery";

function FormComponent(params) {

  const {
    setStepData,
    setActiveStep,
    stepsList,
    addLeadData,
    stepIndex = 7,
  } = params;

  const currentStepData = stepsList[stepIndex].data;

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      'FirstName': currentStepData.FirstName,
      'LastName': currentStepData.LastName,
      'Email': currentStepData.Email,
      'Number': currentStepData.Number,
    }
  });

  _.map(errors, (obj) => {
    $(obj.ref).addClass('input-error');
  });

  function toogleInput(e) {
    $(e.target).removeClass("input-error");
  }

  const onSubmit = (data, e) => {
    setStepData({
      stepIndex: stepIndex,
      data: {
        ...data,
      }
    });
    addLeadData(data);
    setActiveStep(8);
  }

  return (
    <div>
      <Form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              name="FirstName"
              onInput={(e) => toogleInput(e)}
              ref={register({required: true, maxLength: 80})}
              placeholder="John"
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              name="LastName"
              onInput={(e) => toogleInput(e)}
              ref={register({required: true, maxLength: 80})}
              placeholder="Johnson"
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="Email"
            onInput={(e) => toogleInput(e)}
            ref={register({required: true, maxLength: 100, pattern: /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$/i })}
            placeholder="your@email.com"
          />
        </Form.Group>

        <Form.Group controlId="formGridPhone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            name="Number"
            onInput={(e) => toogleInput(e)}
            ref={register({required: true, maxLength: 25 })}
            placeholder="+1-999-999-9999"
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Next
        </Button>
      </Form>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    stepsList: state.steps.list,
  };
};

export default connect(mapStateToProps, { setActiveStep, setStepData, addLeadData })(FormComponent);