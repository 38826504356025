import {
  SET_ACTIVE_STEP,
  SET_STEP_MODE,
  SET_STEP_PARAMS,
  SET_STEP_DATA,
  SET_PVWATTS_DATA,
  SET_LEAD_DATA,
  SET_ERROR_MESSAGE,
  ERROR_CONFIRM,
  SET_HELP_MESSAGE,
  SET_START_REGION,
} from "../actionTypes";
import _ from "lodash";

const initialState = {
  currentStep: 0,
  errorMessage: null,
  helpMessage: null,
  leadData: {
    AddressStreet: "none",
    AddressCity: "none",
    AddressZip: "none",
    State: "CA",
    AverageBill: "none",
    Coordinates: "none",
    FirstName: "none",
    InverterType: "none",
    KWhNeeded: "none",
    LastName: "none",
    Number: "none",
    PanelsCount: "none",
    PanelsGeometry: "none",
    PanelsPosition: "Horizontal",
    PanelsType: "none",
    PaymentType: "none",
    Policy: "none",
    RoofMaterial: "none",
    RoofTilt: "none",
    Email: "none"
  },
  list: [
    {
      id: 0,
      title: "Select your property",
      isActive: true,
      isFinished: false,
      params: {
        mode: "CONFIRM",
      },
      data: {},
    },
    {
      id: 1,
      title: "Select average bill",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 2,
      title: "Select roof material",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 3,
      title: "Select roof tilt",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 4,
      title: "Watch video tutorial",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 5,
      title: "Placing solar panels",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
        selectedFeatureIndex: null,
        action: null,
      },
      data: {
        panelsPosition: "Horizontal",
        panelsData: null,
        inverterType: null,
      },
    },
    {
      id: 6,
      title: "Confirm panels placement",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 7,
      title: "Personal info",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 8,
      title: "Do you want to add a battery?",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    },
    {
      id: 9,
      title: "Select payment method",
      isActive: false,
      isFinished: false,
      params: {
        mode: null,
      },
      data: {},
    }
  ]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_START_REGION: {
      return {
        ...state,
        leadData: {
          ...state.leadData,
          State: action.payload.id,
        }
      };
      return {
        ...state,

      };
    }
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload.error,
      };
    }
    case SET_HELP_MESSAGE: {
      return {
        ...state,
        helpMessage: action.payload.params,
      };
    }
    case ERROR_CONFIRM: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case SET_LEAD_DATA: {
      return {
        ...state,
        leadData: {
          ...state.leadData,
          ...action.payload.data,
        }
      };
    }
    case SET_ACTIVE_STEP: {
      return {
        ...state,
        currentStep: action.payload.stepIndex,
        list: _.map(state.list, (step, index) => {
            return action.payload.stepIndex === index ? { ...step, isActive: true } : { ...step, isFinished: (step.isActive ? action.payload.options.isFinished : step.isFinished), isActive: false };
          }),
      };
    }
    case SET_STEP_MODE: {
      return {
        ...state,
        list: _.map(state.list, (step, index) => {
            if(action.payload.stepIndex === index){
              return {
                ...step,
                params: {
                  ...step.params,
                  mode: action.payload.mode,
                }
              };
            } else {
              return step;
            }
          }),
      };
    }
    case SET_STEP_PARAMS: {
      return {
        ...state,
        list: _.map(state.list, (step, index) => {
            if(action.payload.stepIndex === index){
              return {
                ...step,
                params: {
                  ...step.params,
                  ...action.payload.params,
                }
              };
            } else {
              return step;
            }
          }),
      };
    }
    case SET_STEP_DATA: {
      return {
        ...state,
        list: _.map(state.list, (step, index) => {
            if(action.payload.stepIndex === index){
              return {
                ...step,
                data: {
                  ...step.data,
                  ...action.payload.data,
                }
              };
            } else {
              return step;
            }
          }),
      };
    }
    case SET_PVWATTS_DATA: {
      return {
        ...state,
        list: _.map(state.list, (step, index) => {
            if(action.payload.stepIndex === index){
              return {
                ...step,
                data: {
                  ...step.data,
                  pvwatts: {
                    ...step.data.pvwatts,
                    [action.payload.data.key]: { ...action.payload.data }
                  }
                }
              };
            } else {
              return step;
            }
          }),
      };
    }
    default:
      return state;
  }
}
