import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from 'react-bootstrap';
//import { mapboxViewportChange, mapboxReady } from '../../redux/actions';

function ModalComponent(params) {

  const {
    stepIndex = null,
    stepsList,
    isOpen = false,
    size,
    titleText = "",
    children,
  } = params;

  const currentStep = stepIndex !== null ? stepsList[stepIndex] : {};

  const [show, setShow] = useState(isOpen);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        size={size}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    stepsList: state.steps.list,
  };
};

export default connect(mapStateToProps, {})(ModalComponent);