import {
  MAPBOX_READY,
  RESIZE_WINDOW,
  MAPBOX_VIEWPORT_CHANGE,
  SET_ACTIVE_STEP,
  SET_STEP_MODE,
  SET_STEP_PARAMS,
  SET_STEP_DATA,
  SET_PVWATTS_DATA,
  GEOCODE_RESULT,
  SET_MAPBOX_REF,
  SET_LEAD_DATA,
  SET_ERROR_MESSAGE,
  SET_BOUNDARY_GEOJSON,
  SET_START_REGION,
  ERROR_CONFIRM,
  SET_CONSTANT_DATA,
  SET_HELP_MESSAGE,
} from "./actionTypes";
import fetch from 'cross-fetch';
import ApolloClient from 'apollo-boost';
import * as turf from '@turf/turf';
import { gql } from "apollo-boost";
import _ from "lodash";
import { NREL_API_KEY, GOOGLE_API_KEY, MODES } from "../constants";
import constantData from '../constants.txt';
import polylineDecode from '../flexible-polyline';
import $ from "jquery";

export const mapboxReady = () => ({
  type: MAPBOX_READY,
  payload: {}
});

export const mapboxViewportChange = (viewport) => ({
  type: MAPBOX_VIEWPORT_CHANGE,
  payload: {
    viewport
  }
});

export const changeWindowSize = (width, height) => ({
  type: RESIZE_WINDOW,
  payload: {
    width,
    height
  }
});

export const setActiveStep = (stepIndex, options) => ({
  type: SET_ACTIVE_STEP,
  payload: {
    stepIndex,
    options: {
      isFinished: true,
      ...options,
    },
  }
});

export const setStepMode = ({ mode, stepIndex = 5 }) => ({
  type: SET_STEP_MODE,
  payload: {
    mode,
    stepIndex,
  }
});

export const setStepParams = ({ params, stepIndex }) => ({
  type: SET_STEP_PARAMS,
  payload: {
    stepIndex,
    params,
  }
});

export const setStepData = ({ data, stepIndex }) => ({
  type: SET_STEP_DATA,
  payload: {
    stepIndex,
    data,
  }
});

export const setPVWattsData = ({ data, stepIndex }) => ({
  type: SET_PVWATTS_DATA,
  payload: {
    stepIndex,
    data,
  }
});

export const addLeadData = (data) => ({
  type: SET_LEAD_DATA,
  payload: {
    data
  }
});

export const setMapboxRef = (ref) => ({
  type: SET_MAPBOX_REF,
  payload: {
    ref
  }
});

export const setGeocodeResult = (result) => ({
  type: GEOCODE_RESULT,
  payload: {
    result
  }
});

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  payload: {
    error
  }
});

export const errorConfirm = () => ({
  type: ERROR_CONFIRM,
  payload: {}
});

export const setBoundaryGeojson = (geojson) => ({
  type: SET_BOUNDARY_GEOJSON,
  payload: {
    geojson
  }
});

export const setStartRegion = (id) => ({
  type: SET_START_REGION,
  payload: {
    id
  }
});

export const setConstantData = (data) => ({
  type: SET_CONSTANT_DATA,
  payload: {
    data
  }
});

export const setHelpMessage = (params) => ({
  type: SET_HELP_MESSAGE,
  payload: {
    params
  }
});


///////////////////////

export const getConstantData = () => {
  return dispatch => {
    $.getJSON(constantData, function( data ) {
      dispatch(setConstantData(data));
    });
  }
};

const getAddressValueByType = ({ type, address }) => {
  const result = _.find(address, (comp) => {
    return comp.types.indexOf(type) !== -1;
  });

  return result && result.long_name;
}

const getMonthlyPayment = (amount, rate, months) => {
  const r = rate/100/12;
  const p2 = ( Math.pow((1 + r),months) - 1 );
  const p3 = r * Math.pow((1 + r),months);
  const payment = amount / ( p2 / p3 );
  return payment;
}

export const geocodeAddress = ({ address }) => {
  return dispatch => {
    fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + GOOGLE_API_KEY)
      .then(response => response.json())
      .then(json => {
        const result = json.results[0];

        if(result){
          dispatch(addLeadData({ 'AddressStreet': getAddressValueByType({ address: result.address_components, type: "street_number" }) + " " + getAddressValueByType({ address: result.address_components, type: "route" }) }));
          dispatch(addLeadData({ 'AddressCity': getAddressValueByType({ address: result.address_components, type: "locality" }) }));
          dispatch(addLeadData({ 'AddressZip': getAddressValueByType({ address: result.address_components, type: "postal_code" }) }));
          dispatch(setGeocodeResult(result));
          dispatch(setStepMode({ mode: MODES.PIN.CONFIRM.id, stepIndex: 0 }));
        } else {
          if(json.status === "ZERO_RESULTS"){
            dispatch(setErrorMessage({ error: "Invalid address", message: "The entered address will not be found.\nPlease put a pin on the map." }));
            dispatch(setStepMode({ mode: MODES.PIN.CHANGE.id, stepIndex: 0 }));
            dispatch(setActiveStep(0, { isFinished: false }));
          } else {
            dispatch(setErrorMessage({ error: json.status, message: json.error_message }));
            dispatch(setStepMode({ mode: MODES.PIN.CHANGE.id, stepIndex: 0 }));
          }
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage({ title: "API_ERROR", message: String(error) }));
      });
  }
};

export const getUtilityRate = ({ coords, stepIndex = 1, averageBill }) => {
  return dispatch => {
    fetch("https://developer.nrel.gov/api/utility_rates/v3.json?api_key=" + NREL_API_KEY + "&lat=" + coords.lat + "&lon=" + coords.lon)
      .then(response => response.json())
      .then(json => {
        dispatch(setStepData({ data: {
          utility_rate: json.outputs,
          kWh_month: json.outputs && averageBill && Math.round(averageBill/(json.outputs.residential * 1.6)),
          kWh_year: json.outputs && averageBill && Math.round(averageBill/(json.outputs.residential * 1.6))*12,
        }, stepIndex: stepIndex }));
      })
      .catch((error) => {
        dispatch(setErrorMessage({ title: "API_ERROR", message: String(error) }));
      });
  }
};

const isShowOptimizers = (data) => {
  return data.BatteryType !== "don't need" && (data.InverterType === "Micro-Inverters: Enphase IQ7+" || data.InverterType === "Single Inverter: SMA");
}

const getProposalUrlParams = (params, panelsData) => {
  const {
    PanelsCount,
    BatteryType,
    InverterType,
    PanelsType,
    KWhProduced,
    RoofMaterial,
    RoofTilt,
    AverageBill,
  } = params;

  const kw = PanelsCount * (panelsData.watt / 1000);

  const batteryPrices = {
    "Enphase Encharge 10": 13100,
    "Tesla - Powerwall 2": 15500,
    "LG RESU10H": 8400,
    "don't need": 0,
  };

  const system_size = (PanelsCount * panelsData.watt) / 1000;
  const cost_PPW = system_size * 1000 * (system_size <= 5.99 ? 2.25 : (system_size < 10.99 ? 2.2 : (system_size >= 20.99) ? 2.1 : (system_size > 10.99) ? 2.15 : 0 ) );
  const panel_cost = system_size * panelsData.adder * 1000;
  const totalPrice = (RoofTilt === "Flat" ? 20 * PanelsCount : 0) +
                      (RoofMaterial === "Clay Terracota" ? 114 * PanelsCount : 0) +
                      batteryPrices[BatteryType] +
                      (system_size >= 6 ? system_size * 0.5 * 1000 : ( system_size < 6 ? 3000 : 0 )) +
                      (cost_PPW+panel_cost);

  const tax = 0.26;
  const withoutTax = 1 - tax;

  const p1_y_7 = ( (totalPrice*tax)/(1-0.08) + (totalPrice*withoutTax)/(1 - 0) );
  const p1_y_12 = ( (totalPrice*tax)/(1-0.08) + (totalPrice*withoutTax)/(1 - 0.06) );
  const p1_y_15 = ( (totalPrice*tax)/(1-0.08) + (totalPrice*withoutTax)/(1 - 0.14) );
  const p1_y_20 = ( (totalPrice*tax)/(1-0.08) + (totalPrice*withoutTax)/(1 - 0.19) );

  const p2_y_10 = (totalPrice/(1-0.1925));
  const p2_y_15 = (totalPrice/(1-0.2250));
  const p2_y_20 = (totalPrice/(1-0.2650));
  const p2_y_25 = (totalPrice/(1-0.24));

  const p3 = (totalPrice+1000);

  function percentage (num, per) {
    return (num/100)*per;
  }

  let g_buf = -(totalPrice - batteryPrices[BatteryType]);
  let g = [];

  for (let i = 1; i < (25 * 12); i++) {
    const g_value = (g_buf + AverageBill);
    g_buf = percentage(g_value, g_value < 0 ? 99.42 : 100.58);
    if ((i % 12) === 0) {
      g.push(g_buf.toFixed(2));
    }
  }

  const getRoundedValue = value => Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const proposalParams = {
    "b_home": Math.trunc(5911*kw).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "b_tax": Math.trunc(totalPrice*tax).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "b_co2": Math.trunc(942*25*kw).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "b_trees": Math.trunc(40*25*kw).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "b_smog": getRoundedValue(0.707*kw),
    "p_now": Math.trunc(totalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "p_wp": (( totalPrice - batteryPrices[BatteryType] ) / (system_size * 1000)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "g": g.join(','),

    "p1_m_7": getRoundedValue(getMonthlyPayment(p1_y_7, 4.99, 7*12)),
    "p1_m_12": getRoundedValue(getMonthlyPayment(p1_y_12, 2.99, 12*12)),
    "p1_m_15": getRoundedValue(getMonthlyPayment(p1_y_15, 2.99, 15*12)),
    "p1_m_20": getRoundedValue(getMonthlyPayment(p1_y_20, 2.99, 20*12)),
    "p1_y_7": getRoundedValue(p1_y_7),
    "p1_y_12": getRoundedValue(p1_y_12),
    "p1_y_15": getRoundedValue(p1_y_15),
    "p1_y_20": getRoundedValue(p1_y_20),

    "p1_m_7t": getRoundedValue(getMonthlyPayment(p1_y_7*withoutTax, 4.99, 7*12)),
    "p1_m_12t": getRoundedValue(getMonthlyPayment(p1_y_12*withoutTax, 2.99, 12*12)),
    "p1_m_15t": getRoundedValue(getMonthlyPayment(p1_y_15*withoutTax, 2.99, 15*12)),
    "p1_m_20t": getRoundedValue(getMonthlyPayment(p1_y_20*withoutTax, 2.99, 20*12)),
    "p1_y_7t": getRoundedValue(p1_y_7*withoutTax),
    "p1_y_12t": getRoundedValue(p1_y_12*withoutTax),
    "p1_y_15t": getRoundedValue(p1_y_15*withoutTax),
    "p1_y_20t": getRoundedValue(p1_y_20*withoutTax),

    "p2_m_10": getRoundedValue(getMonthlyPayment(p2_y_10, 1.49, 10*12)),
    "p2_m_15": getRoundedValue(getMonthlyPayment(p2_y_15, 1.49, 15*12)),
    "p2_m_20": getRoundedValue(getMonthlyPayment(p2_y_20, 0.99, 20*12)),
    "p2_m_25": getRoundedValue(getMonthlyPayment(p2_y_25, 1.99, 25*12)),
    "p2_y_10": getRoundedValue(p2_y_10),
    "p2_y_15": getRoundedValue(p2_y_15),
    "p2_y_20": getRoundedValue(p2_y_20),
    "p2_y_25": getRoundedValue(p2_y_25),

    "p2_m_10t": getRoundedValue(getMonthlyPayment(p2_y_10*withoutTax, 1.49, 10*12)),
    "p2_m_15t": getRoundedValue(getMonthlyPayment(p2_y_15*withoutTax, 1.49, 15*12)),
    "p2_m_20t": getRoundedValue(getMonthlyPayment(p2_y_20*withoutTax, 0.99, 20*12)),
    "p2_m_25t": getRoundedValue(getMonthlyPayment(p2_y_25*withoutTax, 1.99, 25*12)),
    "p2_y_10t": getRoundedValue(p2_y_10*withoutTax),
    "p2_y_15t": getRoundedValue(p2_y_15*withoutTax),
    "p2_y_20t": getRoundedValue(p2_y_20*withoutTax),
    "p2_y_25t": getRoundedValue(p2_y_25*withoutTax),

    "p3_m_12": getRoundedValue(getMonthlyPayment(p3, 4.99, 12*12)),
    "p3_m_20": getRoundedValue(getMonthlyPayment(p3, 5.99, 20*12)),
    "p3_y_12": getRoundedValue(p3),
    "p3_y_20": getRoundedValue(p3),

    "p3_m_8t": getRoundedValue(getMonthlyPayment(p3*withoutTax, 4.99, 8*12)),
    "p3_m_15t": getRoundedValue(getMonthlyPayment(p3*withoutTax, 4.99, 15*12)),
    "p3_m_20t": getRoundedValue(getMonthlyPayment(p3*withoutTax, 5.99, 20*12)),
    "p3_y_8t": getRoundedValue(p3*withoutTax),
    "p3_y_15t": getRoundedValue(p3*withoutTax),
    "p3_y_20t": getRoundedValue(p3*withoutTax),

    "d_inverter": InverterType,
    "d_battery": BatteryType === "don't need" ? "n/a" : BatteryType,
    "d_panels": PanelsType,
    "d_panels_q": PanelsCount,
    "d_kw": kw.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    "s_roof": RoofMaterial,
  };

  let url_params = "?";
  _.map(proposalParams, (value, key) => {
    url_params+= "&" + key + "=" + value;
  });

  return url_params;
}

export const pushLeadCRM = (params, panelsData) => {
  return dispatch => {
    const formData = new FormData();
    formData.append('arguments', JSON.stringify({
      "Address_street": params.AddressStreet,
      "Address_city": params.AddressCity,
      "Address_zip": params.AddressZip,
      "State": params.State,
      "FirstName": params.FirstName,
      "LastName": params.LastName,
      "AverageBill": params.AverageBill,
      "RoofMaterial": params.RoofMaterial,
      "RoofTilt": params.RoofTilt,
      "Email": params.Email,
      "Number": params.Number,
      "InverterType": params.InverterType,
      "KWhNeeded": params.KWhNeeded,
      "KWhProduced": String(params.KWhProduced),
      "PanelsCount": params.PanelsCount,
      "PanelsGeometry": params.PanelsGeometry,
      "PanelsPosition": params.PanelsPosition,
      "PanelsType": params.PanelsType,
      "BatteryType": params.BatteryType,
    }));

    localStorage.setItem('panelsGeometry', params.PanelsGeometry);
    localStorage.setItem('panelsData', JSON.stringify(panelsData));

    fetch("/simple-php-proxy.php?url=https%3A%2F%2Fwww.zohoapis.com%2Fcrm%2Fv2%2Ffunctions%2Fleadsdesigntoolwebsite%2Factions%2Fexecute%3Fauth_type%3Dapikey%26zapikey%3D1003.fcdbf72823e0d8d152e76c0ff7dfe63f.5fa888c07ac5af74878815605838315b&full_headers=1&full_status=1", {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(json => {
        const redirectUrl = "https://greempower.com/proposal/" + getProposalUrlParams(params, panelsData);
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        dispatch(setErrorMessage({ title: "API_ERROR", message: String(error) }));
      });

    const redirectUrl = "https://greempower.com/proposal/" + getProposalUrlParams(params, panelsData);
    //window.location.href = redirectUrl;
  }
};

export const deletePVWatts = ({ key, pvwattsList, stepIndex = 5 }) => {
  return dispatch => {
    const newList = _.filter(pvwattsList, function(o) { return key !== o.key; });
    dispatch(setStepData({ data: {
        pvwatts: {
          ...newList,
        },
      }, stepIndex: stepIndex }));
  }
};

export const getPVWatts = ({
    coords,
    stepIndex = 5,
    system_capacity,
    module_type = 1,
    losses = 14,
    array_type = 1,
    tilt,
    azimuth,
    inv_eff = 96,
    panels_count = 0,
    key = 0,
    pvwattsList,
    panelsData,
  }) => {
  return dispatch => {
    dispatch(setStepData({ stepIndex: stepIndex, data: { isPanelsDataLoading: true, }}));
    fetch("https://developer.nrel.gov/api/pvwatts/v6.json?api_key=" + NREL_API_KEY +
      "&lat=" + coords.lat +
      "&lon=" + coords.lon +
      "&system_capacity=" + system_capacity +
      "&module_type=" + module_type +
      "&losses=" + losses +
      "&array_type=" + array_type +
      "&tilt=" + tilt +
      "&azimuth=" + azimuth +
      "&inv_eff=" + inv_eff
    )
      .then(response => response.json())
      .then(json => {
        if(json.outputs){
          dispatch(setStepData({ data: { isPanelsDataLoading: false }, stepIndex: stepIndex }));
          dispatch(setPVWattsData({ data: { key, outputs: json.outputs, azimuth: azimuth, panels_kWh: json.outputs.ac_annual*panels_count }, stepIndex: stepIndex })); /*(((panelsData.width/1000)*(panelsData.height/1000))**/
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage({ title: "API_ERROR", message: String(error) }));
      });
  }
};






